import Axios from './Axios';
import axios from "axios";

class OrganizationService {

  index() {
    return Axios({
      method: "GET",
      url: 'organization'
    });
  }

  orgIndex(id) {
    return Axios({
      method: "GET",
      url: 'organization/' + id,
    })
  }

  store(item) {
    return Axios({
      method: "POST",
      url: 'organization',
      data: item
    })
  }

  update(data) {
    console.log(data)
    return Axios({
      method: "PATCH",
      url: "organization/" + data.id,
      data: data,
    })
  }

  delete(item) {
    return Axios({
      method: "DELETE",
      url: 'organization/' + item.id,
    })
  }

  personOrganizations(id) {
    return Axios({
      method: "GET",
      url: 'person_organizations/' + id
    });
  }

  organization_users(organization_id) {
    return Axios({
      method: "GET",
      url: 'organization-users/' + organization_id
    })
  }

  user_created_organizations() {
    return Axios({
      method: "GET",
      url: "user-managed-organizations"
    });
  }

  admin_parent_organization() {
    return Axios({
      method: "GET",
      url: "admin-parent-organization"
    });
  }

  facilityManagerOrganizations(user_id) {
    return Axios({
      method: "GET", url: "facility-manager-organizations/" + user_id
    });
  }

  uploadCompanyLogo = async (item, org_id) => {
    const baseURL = process.env.VUE_APP_API_URL;
    const url = baseURL + 'organization-logo/' + org_id;
    const access_token = localStorage.getItem('token');
    return await axios.post(url, item, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'multipart/form-data',
        'Authorization': 'Bearer ' + access_token
      }
    });
  }
}

export default new OrganizationService()
