<template>
    <div>
        <v-card outlined class="mt-n3" tile>
            <v-card-title class="pa-0 ma-0">
                <v-toolbar flat class="pt-2">
                    <div align="left">
                        <v-toolbar-title
                                class="
                                info--text
                                text-uppercase
                                font-weight-bold
                                text--darken-1
                                justify-center
                            "
                        >
                            <v-icon color="info">mdi-home-city</v-icon>
                            Organization Profile
                        </v-toolbar-title>
                    </div>
                    <v-spacer></v-spacer>
                    <div align="right">
                        <v-tooltip left>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                        color="info"
                                        dark
                                        fab
                                        x-small
                                        class="text-capitalize my-auto ml-1"
                                        elevation="0"
                                        @click="completeOrganizationProfile"
                                        v-bind="attrs"
                                        v-on="on"
                                >
                                    <v-icon>mdi-pencil-outline</v-icon>
                                </v-btn>
                            </template>
                            <span>{{ completeProfile ? 'Update' : 'Complete' }} Profile</span>
                        </v-tooltip>
                    </div>
                </v-toolbar>
            </v-card-title>

            <v-divider></v-divider>

            <v-card-text class="mb-0 pb-0">
                <v-form class="pt-3 pb-0">
                    <v-row>
                        <v-col>
                            <v-text-field dense v-model="organization.legal_name" label="Legal Name"
                                          :error-messages="apiErrors.legal_name" outlined :disabled="completeProfile"
                            >
                            </v-text-field>
                            <v-text-field dense v-model="organization.org_code" label="Organization Code"
                                          :error-messages="apiErrors.org_code" outlined :disabled="completeProfile"
                            ></v-text-field>
                            <v-text-field dense v-model="organization.reg_number" label="Business Reg No"
                                          :error-messages="apiErrors.reg_number" outlined :disabled="completeProfile"
                            ></v-text-field>
                            <v-select dense v-model="organization.business_type" :items="business_types"
                                      item-text="type" item-value="id" label="Bussiness Type"
                                      :error-messages="apiErrors.business_type" outlined :disabled="completeProfile"
                            ></v-select>
                            <v-text-field dense v-model="organization.phone_number" label="Phone Number"
                                          :error-messages="apiErrors.phone_number" outlined :disabled="completeProfile"
                            ></v-text-field>
                            <v-text-field dense v-model="organization.email_address" label="Email"
                                          :error-messages="apiErrors.email_address" outlined :disabled="completeProfile"
                            ></v-text-field>
                            <v-text-field dense v-model="organization.website" label="Website"
                                          :error-messages="apiErrors.website" outlined :disabled="completeProfile"
                            ></v-text-field>

                        </v-col>
                        <v-col>
                            <div color="red" justify="center" align="center">
                                <v-file-input color="grey" append-icon="mdi-file-upload" prepend-icon="" outlined
                                              dense type="file" ref="file" :name="file_name"
                                              @change="uploadCompanyLogo">
                                    <template v-slot:label v-if="!completeProfile">
                                        <span class="input__label">
                                            Company Logo
                                            <v-icon small color="error" class="mt-n2">mdi-star-outline</v-icon>
                                        </span>
                                    </template>
                                    <template v-slot:label v-else>
                                        <span class="input__label">
                                            Company Logo
                                            <v-icon small color="success">mdi-check</v-icon>
                                        </span>
                                    </template>
                                </v-file-input>


                                <v-row justify="center" align="center">
                                    <div class="mx-auto text-center">
                                        <image-input v-model="logoFileToBeUploaded">
                                            <div slot="activator">
                                                <v-avatar size="100px" v-ripple v-if="!logoFileToBeUploaded.imageURL"
                                                          class="grey lighten-3 mb-3 border">
                                                    <span>Click to add logo</span>
                                                </v-avatar>
                                                <v-avatar size="100px" v-ripple v-else class="grey lighten-3"
                                                          style="border: 1px solid grey" border>
                                                    <v-img max-height="100px" max-width="100px"
                                                           v-model="logoFileToBeUploaded" class="grey lighten-3"
                                                           rounded :src="logoFileToBeUploaded.imageURL"
                                                           :lazy-src="logoFileToBeUploaded.imageURL"
                                                    ></v-img>
                                                </v-avatar>
                                            </div>
                                        </image-input>

                                        <v-slide-x-transition>
                                            <div v-if="logoFileToBeUploaded.imageURL !== oldImageURL" class="my-3">
                                                <v-btn color="teal" dark small @click="handleLogoUpload"
                                                       :loading="loadingUpload">
                                                    Upload
                                                </v-btn>
                                            </div>
                                        </v-slide-x-transition>
                                        <h4>Company logo</h4>
                                    </div>
                                </v-row>
                            </div>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
        </v-card>

        <v-row justify="center">
            <v-dialog v-model="showEditCompanyProfileModal" scrollable max-width="600px">
                <v-card>
                    <v-card-title class="grey--text text-h5">
                        Company Profile
                    </v-card-title>
                    <v-divider></v-divider>

                    <!-- Modal inputs -->
                    <v-card-text class="mb-0 pb-0">
                        <v-form class="pt-7 pb-0">
                            <v-text-field
                                    dense
                                    v-model="organization.legal_name"
                                    label="Legal Name"
                                    :error-messages="apiErrors.legal_name"
                                    outlined
                            ></v-text-field>
                            <v-text-field
                                    dense
                                    v-model="organization.org_code"
                                    label="Organization Code"
                                    :error-messages="apiErrors.org_code"
                                    outlined
                            ></v-text-field>
                            <v-text-field
                                    dense
                                    v-model="organization.reg_number"
                                    label="Business Reg No"
                                    :error-messages="apiErrors.reg_number"
                                    outlined
                            ></v-text-field>
                            <v-select
                                    dense
                                    v-model="organization.business_type"
                                    :items="business_types"
                                    item-text="type"
                                    item-value="id"
                                    label="Bussiness Type"
                                    :error-messages="apiErrors.business_type"
                                    outlined
                            ></v-select>
                            <v-text-field
                                dense
                                v-model="organization.phone_number"
                                label="Phone Number"
                                :error-messages="apiErrors.phone_number"
                                outlined
                            ></v-text-field>
                            <v-text-field
                                dense
                                v-model="organization.email_address"
                                label="Email"
                                :error-messages="apiErrors.email_address"
                                outlined
                            ></v-text-field>
                            <v-text-field dense v-model="organization.website" label="Website"
                                          :error-messages="apiErrors.website" outlined>
                            </v-text-field>

                        </v-form>
                    </v-card-text>
                    <v-divider></v-divider>

                    <!-- Modal actions-->
                    <v-card-actions>
                        <v-btn color="red darken-1" text @click="closeModal" class="text-capitalize mx-1">
                            Cancel
                        </v-btn>

                        <v-spacer></v-spacer>

                        <v-btn color="green darken-1 white--text" @click="updateCompanyProfile"
                               class="text-capitalize mx-1">
                            {{ completeProfile ? "Edit" : "Complete" }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>

    </div>
</template>

<script>
import OrganizationService from '../../services/organization.service';
import Organization from '../../models/Organization';
import ImageInput from "@/components/ImageInput.vue";

export default {
    name: "CompanyProfile",
    components: {ImageInput},
    data() {
        return {
            completeProfile: true,
            organization: new Organization(),
            business_types: [],
            showEditCompanyProfileModal: false,
            file_name: 'company_logo',
            logoFileToBeUploaded: [],
            loadingUpload: false,
            apiErrors: [],
            selectedFile: null
        }
    },
    created() {
        this.$nextTick(() => {
            this.adminParentOrganization()
        });
    },
    methods: {
        adminParentOrganization() {
            OrganizationService.admin_parent_organization().then(
                success => {
                    if (success.data.data != null) {
                        console.log(success.data.data)
                        let data = success.data.data;
                        this.organization = success.data.data.attributes
                        this.organization.id = data.id
                    }
                },
                error => {
                    console.log(error)
                }
            )
        },

        completeOrganizationProfile() {
            this.showEditCompanyProfileModal = true;
        },

        updateCompanyProfile() {
            OrganizationService.update(this.organization).then(
                success => {
                    if (success.status === 200) {
                        let data = success.data.data;
                        this.organization = success.data.data.attributes
                        this.organization.id = data.id
                    }
                },
                error => {
                    console.log(error)
                }
            );
        },

        closeModal() {
            this.showEditCompanyProfileModal = false;
        },

        uploadCompanyLogo(event) {
            console.log(event.target)
            this.selectedFile = event.target.files[0];
        },

        handleLogoUpload() {
            OrganizationService.uploadCompanyLogo(this.logoFileToBeUploaded.formData, this.organization.id)
                .then(response => console.log(response))
                .catch(error => console.log(error));
        }
    }
}
</script>