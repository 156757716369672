<template>
    <div>
        <v-data-table :headers="headers" :items="smsData" @row-click="viewDetails">
            <template v-slot:item.sent_date="{ item }">
                {{ formatDate(item.sent_date) }}
            </template>
            <template v-slot:item.message="{ item }">
                <div>
                    <span v-if="!showFullMessage">{{ formatMessage(item.message) }}</span>
                    <span v-if="showFullMessage">{{ item.message }}</span>
                    <v-btn small text @click="viewFullMessage">{{ showFullMessage ? 'Show Less' : 'View More' }}</v-btn>
                </div>
            </template>
        </v-data-table>
        <v-dialog v-if="selectedSms" v-model="detailsDialog" max-width="600">
            <v-card>
                <v-card-title class="headline">{{ selectedSms.message }}</v-card-title>
                <v-card-text>
                    <v-list>
                        <v-list-item v-for="(value, key) in selectedSmsDetails" :key="key">
                            <v-list-item-content>
                                <v-list-item-title>{{ key }}</v-list-item-title>
                                <v-list-item-subtitle>{{ value }}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="detailsDialog = false">Close</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>


<script>
import ReportsService from "@/services/reports.service";

export default {
    name: "SmsLogs",
    data() {
        return {
            headers: [
                { text: "SMS ID", value: "sms_id" },
                { text: "Message", value: "message" },
                { text: "Phone", value: "phone" },
                { text: "Sent Date", value: "sent_date" },
                { text: "User Name", value: "user_name" },
            ],
            smsData: [],
            selectedSms: null,
            selectedSmsDetails: {},
            detailsDialog: false,
            loading: false,
            showFullMessage: false,
        };
    },
    methods: {
        viewDetails(item) {
            this.selectedSms = item;
            this.selectedSmsDetails = {
                "SMS ID": item.sms_id,
                Phone: item.phone,
                "Sent Date": this.formatDate(item.sent_date),
                "User Name": item.user_name,
            };
            this.detailsDialog = true;
        },
        getSmsLogs() {
            const org_id = 1;
            this.loading = true;
            ReportsService.smsLogs(org_id)
                .then((response) => {
                    if (response.status === 200) {
                        this.smsData = response.data;
                    }
                })
                .catch((error) => {
                    console.log(error);
                    this.$store.dispatch("alert/error", error.response.data.message);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        formatDate(date) {
            return new Date(date).toLocaleString();
        },
        formatMessage(message) {
            if (message.length > 70) {
                return message.substring(0, 100) + "...";
            } else {
                return message;
            }
        },
        viewFullMessage(item) {
            item.showFullMessage = true;
            item.showViewMore = false;
        },
    },
    created() {
        this.getSmsLogs();
    },
};
</script>
